import { TransactionPayloadType, TransactionRecordType, TransactionPaginateType } from 'models/Transaction';
import { client } from './axios';

const deposit = (body: TransactionPayloadType): Promise<TransactionRecordType> =>
  client.post(`/api/transactions/deposit`, body);

const withdraw = (body: TransactionPayloadType): Promise<TransactionRecordType> =>
  client.post(`/api/transactions/withdraw`, body);

const fetchTransactions = (params?: PaginateParams): Promise<TransactionPaginateType> =>
  client.get(`/api/transactions`, { params });

const transactionService = {
  deposit,
  withdraw,
  fetchTransactions,
};
export default transactionService;
