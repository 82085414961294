import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

const CloseButton = (props: IconButtonProps) => {
  return (
    <IconButton
      size='small'
      sx={{
        position: 'absolute',
        top: 12,
        right: 12,
        color: '#000',
        '&:hover': {
          backgroundColor: '#ccc',
        },
      }}
      {...props}
    >
      <Close />
    </IconButton>
  );
};

export default CloseButton;
