import App from 'App';
import 'App.scss';
import ReactDOM from 'react-dom/client';
import { install } from 'resize-observer';
import 'tailwindcss/tailwind.css';

if (!window.ResizeObserver) install();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
