import { DialogContent, DialogTitle, Divider, Grid, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components';
import { DateTime } from 'luxon';
import { transactionService } from 'services';
import { getTextTransactionStatus, getTextTransactionType } from 'utils/common';
import { TransactionStatusEnum } from 'utils/enum';
import merge from 'utils/merge';

type PopupProps = PopupController & {};

const PopupWithdrawHistory = ({ onClose }: PopupProps) => {
  const { data } = useQuery(['transactionService.fetchTransactions'], () => transactionService.fetchTransactions());

  const { items = [] } = data ?? {};

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle className='text-center text-secondary-dark'>LỊCH SỬ RÚT TIỀN</DialogTitle>
      <div className='flex justify-center'>
        <Divider className='border-b-2 border-secondary-main w-2/3' />
      </div>
      <DialogContent className='text-center'>
        <Grid container spacing={2} className='mt-1 mb-4'>
          {items.map((item) => (
            <Grid item xs={12} className='flex items-center justify-center' key={item.id}>
              <Paper className='bg-paper-white rounded-lg px-8 py-4 text-left'>
                <div>
                  Loại: <span className='font-semibold'>{getTextTransactionType(item.type)}</span>
                </div>
                <div>
                  Số tiền: <span className='font-semibold'>{item.amount}</span>
                </div>
                <div>
                  Thời gian:{' '}
                  <span className='font-semibold'>
                    {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}
                  </span>
                </div>
                <div>
                  Ghi chú: <span className='font-semibold'>{item.note}</span>
                </div>
                <div>
                  Trạng thái:{' '}
                  <span
                    className={merge('font-semibold', {
                      'text-success': item.status === TransactionStatusEnum.Accept,
                      'text-info': item.status === TransactionStatusEnum.Pending,
                      'text-error': [TransactionStatusEnum.Reject, TransactionStatusEnum.Cancel].includes(item.status),
                    })}
                  >
                    {getTextTransactionStatus(item.status)}
                  </span>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </>
  );
};

export default PopupWithdrawHistory;
