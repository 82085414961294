import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { randomScalingFactor } from 'utils/common';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderRadius: 24,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      font: {
        size: 24,
      },
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      max: 25,
    },
  },
};

const labels = Array.from({ length: 12 }, (_, i) => i + 1);

export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => randomScalingFactor(1, 15)),
      backgroundColor: '#fff',
    },
    {
      data: labels.map(() => randomScalingFactor(1, 15)),
      backgroundColor: '#ee3840',
    },
  ],
};

const VerticalBarChart = () => {
  return <Bar options={options} data={data} />;
};

export default VerticalBarChart;
