import { Link } from '@mui/icons-material';
import { DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { CloseButton } from 'components';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';

const PopupInvite = ({ onClose }: PopupController) => {
  const { refCode } = useSelector(profileSelector);

  const { enqueueSnackbar } = useSnackbar();

  const [refLink] = useMemo(
    () => [window.location.origin + '/auth/register' + stringify({ refCode }, { addQueryPrefix: true })],
    [refCode],
  );

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle className='text-center text-secondary-dark'>LINK MỜI BẠN BÈ</DialogTitle>
      <div className='flex justify-center'>
        <Divider className='border-b-2 border-secondary-main w-2/3' />
      </div>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder='Link'
              color='warning'
              value={refLink}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position='start'>
                    <IconButton
                      onClick={() => {
                        copy(refLink);
                        enqueueSnackbar('Đã copy');
                      }}
                    >
                      <Link />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PopupInvite;
