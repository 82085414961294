import { client } from './axios';

const login = (body: LoginBody): Promise<LoginResponse> => client.post(`/api/auth/login`, body);
const register = (body: RegisterBody): Promise<RegisterResponse> => client.post(`/api/auth/regitster`, body);
const getProfile = (): Promise<UserRecordType> => client.get(`/api/users/me`);

const updateProfile = (body: UpdateProfileBody): Promise<UserRecordType> => client.put(`/api/users`, body);
const changePassword = (body: UpdatePasswordBody): Promise<UserRecordType> =>
  client.post(`/api/users/change-password`, body);
const changePasswordWithdraw = (body: UpdatePasswordBody): Promise<UserRecordType> =>
  client.post(`/api/users/change-password-withdraw`, body);

const getBank = (body: GetBankBody): Promise<BankRecordType> => client.post(`/api/users/bank`, body);

const authService = {
  login,
  register,
  getProfile,
  updateProfile,
  changePassword,
  changePasswordWithdraw,
  getBank,
};
export default authService;
