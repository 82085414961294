import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

const PopupNotification = ({ onClose }: PopupController) => {
  return (
    <>
      <DialogTitle>THÔNG BÁO</DialogTitle>

      <DialogContent>
        Chúc mừng bạn đã may mắn nhận được đơn hàng giá trị lớn ngẫu nhiên, vui lòng liên hệ với chăm sóc khách hàng để
        được hỗ trợ !
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='contained' onClick={onClose}>
          Đồng ý
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupNotification;
