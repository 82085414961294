export enum TransactionStatusEnum {
  Pending = 'pending',
  Reject = 'reject',
  Accept = 'accept',
  Cancel = 'cancel',
}

export enum TransactionTypeEnum {
  Withdraw = 'withdraw',
  Deposit = 'deposit',
  Promotion = 'promotion',
}

export enum OrderStatusEnum {
  Pending = 'pending',
  Waiting = 'waiting',
  Reject = 'reject',
  Accept = 'accept',
  Cancel = 'cancel',
}
