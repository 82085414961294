import { ArrowBackIos } from '@mui/icons-material';
import { Paper, Container, IconButton, Grid, Button } from '@mui/material';
import { privateRoute } from 'routes';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from '@tanstack/react-query';
import { orderService, queryClient } from 'services';
import { useSearch } from 'hooks';
import { OrderStatusEnum } from 'utils/enum';
import { LoadingButton } from '@mui/lab';
import { getColorButton, getTextButton } from 'utils/common';

const Order = () => {
  const [dataSearch] = useSearch({ populate: 'product' });

  const { data } = useQuery(['orderService.fetchOrders', dataSearch], () => orderService.fetchOrders(dataSearch));
  const { items = [] } = data ?? {};

  const { mutate: updateOrder, isLoading } = useMutation(orderService.updateOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['orderService.fetchOrders']);
    },
  });

  const handleCancel = (id: string) => {
    updateOrder({ id, status: OrderStatusEnum.Cancel });
  };

  const handleConfirm = (id: string) => {
    updateOrder({ id, status: OrderStatusEnum.Pending });
  };

  return (
    <Container maxWidth='xl' className='p-0 md:px-4 mb-16 xl:mb-0'>
      <Paper className='rounded-none px-4 xl:p-6'>
        <div className='flex items-center'>
          <Link to={privateRoute.order.path}>
            <IconButton color='inherit'>
              <ArrowBackIos />
            </IconButton>
          </Link>
          <div className='font-bold text-lg border-b-[3px] border-secondary-main py-2'>DANH SÁCH ĐẶT HÀNG</div>
        </div>
        <Grid container>
          {items.map((item) => (
            <Grid item xs={12} key={item.id}>
              <div className='flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-0 items-start justify-between py-4 xl:py-8 xl:px-20'>
                <div className='flex items-center justify-start gap-6'>
                  <img src={item.product?.image} className='max-w-[100px] md:h-[120px] md:w-[180px]' />
                  <div className='flex flex-col gap-y-2'>
                    <div className='text-sm md:text-xl text-secondary-main'>{item.product?.name}</div>
                    <div className='text-sm'>
                      <p>
                        Số tiền: <span className='font-semibold'>{item.price}</span>
                      </p>
                      <p>
                        Số lượng: <span className='font-semibold'> {item.amount}</span>
                      </p>
                      <p>
                        Hoa hồng: <span className='font-semibold'>{item.discount}</span>
                      </p>
                      <p>
                        Thời gian:{' '}
                        <span className='font-semibold'>
                          {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {item.status === OrderStatusEnum.Waiting ? (
                  <div className='flex items-center gap-x-4'>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      disableElevation={false}
                      onClick={() => handleCancel(item.id)}
                      loading={isLoading}
                    >
                      Hủy bỏ
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='secondary'
                      disableElevation={false}
                      onClick={() => handleConfirm(item.id)}
                      loading={isLoading}
                    >
                      Xác nhận
                    </LoadingButton>
                  </div>
                ) : (
                  <Button
                    variant='contained'
                    color={getColorButton(item.status)}
                    disableElevation={false}
                    className='min-w-[140px]'
                  >
                    {getTextButton(item.status)}
                  </Button>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default Order;
