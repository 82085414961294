import { OrderStatusEnum, TransactionStatusEnum, TransactionTypeEnum } from './enum';

export const randomScalingFactor = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatBalance = (balance?: number) => {
  return (balance ?? 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export function shuffleArray<T>(array: T[]): T[] {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export const getColorButton = (status: OrderStatusEnum) => {
  if ([OrderStatusEnum.Cancel, OrderStatusEnum.Reject].includes(status)) return 'error';
  else if (status === OrderStatusEnum.Pending) return 'info';
  else if (status === OrderStatusEnum.Waiting) return 'warning';
  else return 'success';
};

export const getTextButton = (status: OrderStatusEnum) => {
  if (status === OrderStatusEnum.Cancel) return 'Đã hủy';
  else if (status === OrderStatusEnum.Reject) return 'Đã bị từ chối';
  else if (status === OrderStatusEnum.Pending) return 'Chờ xác nhận';
  else return 'Đã hoàn thành';
};

export const getTextTransactionStatus = (status: TransactionStatusEnum) => {
  if (status === TransactionStatusEnum.Cancel) return 'Đã hủy';
  else if (status === TransactionStatusEnum.Reject) return 'Đã bị từ chối';
  else if (status === TransactionStatusEnum.Pending) return 'Chờ xác nhận';
  else return 'Đã hoàn thành';
};

export const getTextTransactionType = (type: TransactionTypeEnum) => {
  if (type === TransactionTypeEnum.Withdraw) return 'Rút điểm';
  else if (type === TransactionTypeEnum.Deposit) return 'Nạp điểm';
  else return 'Điều chỉnh điểm';
};
