import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CloseButton, InputNumber, InputPassword } from 'components';
import { TransactionPayloadType } from 'models/Transaction';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { authService, queryClient, transactionService } from 'services';
import { formatBalance } from 'utils/common';

type PopupProps = PopupController & {
  isRequiredWithdraw?: boolean;
};

const PopupWithdraw = ({ isRequiredWithdraw, onClose }: PopupProps) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { balance } = useSelector(profileSelector);
  const dispatch = useDispatch();

  useQuery(['authService.getProfile'], () => authService.getProfile(), {
    onSuccess: (data) => {
      dispatch(signIn(data));
    },
  });

  const { mutate: withdraw, isLoading } = useMutation(transactionService.withdraw, {
    onSuccess: () => {
      queryClient.invalidateQueries(['authService.getProfile']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      withdraw(values as TransactionPayloadType);
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <>
      {!isRequiredWithdraw && <CloseButton onClick={onClose} />}
      <DialogTitle className='text-center text-secondary-dark'>RÚT TIỀN</DialogTitle>
      <div className='flex justify-center'>
        <Divider className='border-b-2 border-secondary-main w-2/3' />
      </div>
      <DialogContent className='text-center md:mx-12'>
        <div className='font-semibold text-secondary-main'>Số dư tài khoản: {formatBalance(balance)}</div>
        <Grid container rowSpacing={2} className='my-4'>
          <Grid item xs={12}>
            <Controller
              name='amount'
              defaultValue=''
              control={control}
              rules={{
                required: 'Số tiền rút không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  placeholder='Số tiền rút'
                  InputProps={{
                    inputComponent: InputNumber,
                  }}
                  sx={{
                    background: '#0000',
                    borderRadius: 1,
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: 'var(--color-paper-white)',
                      borderRadius: 16,
                    },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='passwordWithdraw'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu rút tiền không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Nhập mật khẩu rút tiền'
                  color='warning'
                  onKeyDown={handleKeyDown}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='flex flex-col gap-4 items-center text-center justify-center'>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Đồng ý
        </LoadingButton>
        <div className='text-sm w-[80%]'>
          Lưu ý: Việc rút tiền đang chờ xử lý trong 3-5 phút, nếu tiền không đến vui lòng liên hệ với dịch vụ khách hàng
        </div>
      </DialogActions>
    </>
  );
};

export default PopupWithdraw;
