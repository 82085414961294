import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'components/Spinner';
import { useEffect } from 'react';
import { categoryService } from 'services';

const Home = () => {
  const { data, isLoading } = useQuery(['domainServices.fetchDomains'], () => categoryService.fetchCategories());

  const { items = [] } = data ?? {};
  // const { isMobile } = useWindowDimensions();

  useEffect(() => {
    console.log('items', items);
    if (!items.length) return;
    const randomItems = items.sort(() => Math.random() - 0.5);
    console.log('randomItems', randomItems[0]);
    const { name } = randomItems[0];
    const url = normalizeUrl(name);
    if (url) window.location.href = url;
  }, [items]);

  function normalizeUrl(url: string) {
    try {
      if (!/^https?:\/\//i.test(url)) {
        // Prepend 'http://' if not present
        url = 'http://' + url;
      }

      // Create a URL object to handle the URL parsing
      let normalizedUrl = new URL(url);

      return normalizedUrl.href;
    } catch (error) {
      console.error('Invalid URL', error);
      return null;
    }
  }
  return (
    <Container maxWidth='xl' className='px-0 sm:px-6 mb-16 xl:mb-0'>
      <Spinner loading={isLoading} />
    </Container>
  );
};

export default Home;
