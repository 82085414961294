import { Box, Container, LinearProgress, Paper, Typography, Grid, Dialog } from '@mui/material';
import { PopupWithdraw, PopupWithdrawHistory, VerticalBarChart } from './components';
import { useState } from 'react';
import { profileSelector } from 'reducers/profileSlice';
import { useSelector } from 'react-redux';
import { formatBalance } from 'utils/common';
import { enqueueSnackbar } from 'notistack';

const Wallet = () => {
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openWithdrawHistory, setOpenWithdrawHistory] = useState(false);

  const { balance, balanceDiscount, bankUserNumber } = useSelector(profileSelector);

  const handleOpenWithdraw = () => {
    if (!bankUserNumber) {
      enqueueSnackbar('Vui lòng cập nhật thông tin ngân hàng trước khi rút tiền', { variant: 'error' });
      return;
    }
    setOpenWithdraw(true);
  };

  return (
    <>
      <div className='bg-[#252531]'>
        <Container maxWidth='lg' className='h-[400px] relative'>
          <VerticalBarChart />
          <div className='absolute top-0 bottom-0 left-0 right-0 text-center text-white pt-10'>
            <Typography variant='h4'>{formatBalance(balance)}</Typography>
            <div>Tổng tài sản</div>
          </div>
        </Container>
      </div>
      <Container maxWidth='xl' className='my-4'>
        <Paper className='rounded-none p-8'>
          <div className='font-semibold text-xl border-b-2 py-2'>PHÂN TÍCH THU NHẬP</div>
          <Container maxWidth='sm' className='flex items-center justify-center flex-col gap-4 pt-4'>
            <Typography variant='h4' className='text-secondary-dark font-semibold'>
              {formatBalance(balanceDiscount)}
            </Typography>
            <div className='text-sm'>Thu nhập hoa hồng</div>
            <Box width='100%'>
              <LinearProgress variant='determinate' sx={{ borderRadius: 8, height: 12 }} value={30} color='error' />
            </Box>
          </Container>
        </Paper>
      </Container>
      <Container maxWidth='xl' className='my-4 mb-16 xl:mb-0'>
        <Paper className='rounded-none px-4 py-8 md:p-8'>
          <div className='font-semibold text-xl border-b-2 py-2'>THÔNG TIN CHI TIẾT QUỸ</div>
          <Container maxWidth='lg' className='pt-8'>
            <Grid container columnSpacing={8} rowSpacing={4}>
              <Grid item xs={12} lg={6} className='cursor-pointer'>
                <Paper
                  className='py-4 px-6 flex justify-start items-start gap-4 bg-paper-white h-full'
                  onClick={handleOpenWithdraw}
                >
                  <img src={require('assets/icons/Icon.Withdraw-History.png')} alt='' />
                  <div className='flex flex-col gap-y-2'>
                    <div className='text-secondary-main font-semibold'>RÚT TIỀN</div>
                    <div className='text-sm'>Rút tiền về tài khoản ngân hàng của bạn</div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6} className='cursor-pointer'>
                <Paper
                  className='py-4 px-6 flex justify-start items-start gap-4 bg-paper-white h-full'
                  onClick={() => setOpenWithdrawHistory(true)}
                >
                  <img src={require('assets/icons/Icon.Withdraw.png')} alt='' />
                  <div className='flex flex-col gap-y-2'>
                    <div className='text-secondary-main font-semibold'>LỊCH SỬ RÚT TIỀN</div>
                    <div className='text-sm'>Xem lịch sử rút tiền</div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Container>

      <Dialog fullWidth maxWidth='sm' open={openWithdraw} onClose={() => setOpenWithdraw(false)}>
        <PopupWithdraw onClose={() => setOpenWithdraw(false)} />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={openWithdrawHistory} onClose={() => setOpenWithdrawHistory(false)}>
        <PopupWithdrawHistory onClose={() => setOpenWithdrawHistory(false)} />
      </Dialog>
    </>
  );
};

export default Wallet;
