import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseButton } from 'components';
import { DateTime } from 'luxon';
import { OrderRecordType } from 'models/Order';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { authService, orderService } from 'services';
import { OrderStatusEnum } from 'utils/enum';

type PopupProps = PopupController & {
  item: OrderRecordType;
  setIsSpin: (value: boolean) => void;
};

const PopupOrder = ({ item, setIsSpin, onClose }: PopupProps) => {
  const { isLoggedIn } = useSelector(profileSelector);
  const dispatch = useDispatch();

  const { mutate: updateOrder, isLoading } = useMutation(orderService.updateOrder, {
    onSuccess: () => {
      onClose();
      setIsSpin(false);
    },
    onSettled: () => {
      if (isLoggedIn) {
        authService.getProfile().then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
    onError: () => {
      setIsSpin(false);
    },
  });

  const handleCancel = () => {
    updateOrder({ id: item.id, status: OrderStatusEnum.Cancel });
  };

  const handleConfirm = () => {
    updateOrder({ id: item.id, status: OrderStatusEnum.Pending });
  };

  return (
    <>
      <CloseButton
        onClick={() => {
          onClose();
          setIsSpin(false);
        }}
      />
      <DialogTitle className='text-center text-secondary-dark'>{item.product?.name}</DialogTitle>
      <div className='flex justify-center'>
        <Divider className='border-b-2 border-secondary-main w-2/3' />
      </div>
      <DialogContent className='text-center px-4 md:px-6'>
        <div className='flex items-center justify-center gap-6'>
          <img src={item.product?.image} alt='' className='h-20 md:h-[120px]' />
          <div className='flex flex-col gap-y-2 text-left'>
            <div className='text-xl text-secondary-main'>{item.product?.name}</div>
            <div className='text-xs md:text-sm'>
              <p>
                Số tiền: <span className='font-semibold'>{item.price}</span>
              </p>
              <p>
                Số lượng: <span className='font-semibold'> {item.amount}</span>
              </p>
              <p>
                Hoa hồng: <span className='font-semibold'>{item.discount}</span>
              </p>
              <p>
                Thời gian:{' '}
                <span className='font-semibold'>
                  {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}
                </span>
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className='flex items-center justify-center gap-2 md:gap-6'>
        <LoadingButton
          variant='contained'
          color='primary'
          disableElevation={false}
          onClick={handleCancel}
          loading={isLoading}
        >
          Hủy bỏ
        </LoadingButton>
        <LoadingButton
          variant='contained'
          color='secondary'
          disableElevation={false}
          onClick={handleConfirm}
          loading={isLoading}
        >
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupOrder;
