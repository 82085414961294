import { Home, Person, ShoppingBag, Wallet } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { useWindowDimensions } from 'hooks';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { authRoute, privateRoute } from 'routes';

const StyledListItem = styled(ListItemButton)({
  '&:hover, &.Mui-selected': {
    color: 'var(--color-secondary-main)',
    backgroundColor: 'white !important',
  },
  padding: '32px 16px 12px 16px',

  minWidth: '180px',
  color: 'var(--color-bg-white)',
  fontWeight: 600,
});

const NavItem = ({ path, name, icon }: { path: string; name: string; icon?: any }) => {
  const { pathname } = useLocation();
  const isHome = path === '/';

  return (
    <Link to={path}>
      <StyledListItem
        className='rounded-b-3xl flex justify-center items-center gap-x-2'
        selected={isHome ? pathname === path : pathname.startsWith(path)}
      >
        {icon}
        <span className='whitespace-nowrap'>{name}</span>
      </StyledListItem>
    </Link>
  );
};

const StyledListItemMobile = styled(ListItemButton)({
  height: 60,
  fontSize: 12,
  fontWeight: 500,
  padding: '10px 0px',
  flexDirection: 'column',
  color: '#888',
  '&:hover, &.Mui-selected': {
    color: 'var(--color-secondary-main)',

    '& svg.text-transparent > path': {
      stroke: 'var(--color-secondary-main)',
    },
    '& svg:not(.text-transparent) > path': {
      fill: 'var(--color-secondary-main)',
    },
  },
});

const NavMobileItem = ({ path, url, name, icon }: { path: string; url: string; name: string; icon?: any }) => {
  const { pathname } = useLocation();
  const isHome = path === '/';
  return (
    <Link to={url} className='flex-1'>
      <StyledListItemMobile selected={isHome ? pathname === path : pathname.startsWith(path)}>
        {icon}
        <span className='whitespace-nowrap'>{name}</span>
      </StyledListItemMobile>
    </Link>
  );
};

interface MenuProps {
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Menu = ({ onOpen }: MenuProps) => {
  const { isLoggedIn, username } = useSelector(profileSelector);
  const { isXlScreen } = useWindowDimensions();

  const { wallet, order, home } = privateRoute;
  const { login } = authRoute;

  return (
    <div className='flex xl:gap-16'>
      {isXlScreen ? <NavMobileItem {...home} icon={<Home />} /> : <NavItem {...home} icon={<Home />} />}
      {isLoggedIn && (
        <>
          {isXlScreen ? (
            <>
              <NavMobileItem {...order} icon={<ShoppingBag />} />
              <NavMobileItem {...wallet} icon={<Wallet />} />
            </>
          ) : (
            <>
              <NavItem {...order} icon={<ShoppingBag />} />
              <NavItem {...wallet} icon={<Wallet />} />
            </>
          )}
        </>
      )}
      {isXlScreen &&
        (isLoggedIn ? (
          <StyledListItemMobile onClick={onOpen} className='flex-1'>
            <Person />
            <span className='whitespace-nowrap'>{username}</span>
          </StyledListItemMobile>
        ) : (
          <NavMobileItem {...login} icon={<Person />} />
        ))}
    </div>
  );
};

export default Menu;
