import { OrderPaginateType, OrderRecordType, UpdateOrderType } from 'models/Order';
import { client } from './axios';

const fetchOrders = (params?: PaginateParams): Promise<OrderPaginateType> => client.get(`/api/orders`, { params });
const orderSpin = (): Promise<OrderRecordType> => client.post(`/api/orders/spin`);
const updateOrder = ({ id, ...body }: UpdateOrderType): Promise<OrderRecordType> =>
  client.patch(`/api/orders/${id}`, body);

const orderService = {
  fetchOrders,
  orderSpin,
  updateOrder,
};
export default orderService;
