import { useAnchor } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { privateRoute } from 'routes';

const PrivateLayout = () => {
  const { isReady } = useSelector(systemSelector);
  const { isLoggedIn, isRequiredWithdraw } = useSelector(profileSelector);
  const [anchorEl, open, onOpen, onClose] = useAnchor();
  const [openWithdraw, setOpenWithdraw] = useState(isRequiredWithdraw ?? false);

  useEffect(() => {
    if (isRequiredWithdraw) {
      setOpenWithdraw(true);
    }
  }, [isRequiredWithdraw]);

  return (
    <div className='bg-paper-white'>
      <main className='pt-[64px] md:pt-[80px] pb-6'>
        {isReady ? (
          <Routes>
            {Object.values(privateRoute)
              .filter(({ requiredLogin }: any) => !requiredLogin || isLoggedIn)
              .map(({ path, component: Element }) => (
                <Route key={path} path={path} element={<Element />} />
              ))}
            <Route path='*' element={<Navigate to={privateRoute.home.path} />} />
          </Routes>
        ) : (
          <></>
        )}
      </main>
    </div>
  );
};

export default PrivateLayout;
