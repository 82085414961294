import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useSearch, useWindowDimensions } from 'hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { productService } from 'services';
import { shuffleArray } from 'utils/common';

const Slot = ({ target }: { target: string }) => {
  const { isMobile } = useWindowDimensions();
  const { vip } = useSelector(profileSelector);

  const [dataSearch] = useSearch({ vip });

  const { data } = useQuery(['productService.fetchProducts', dataSearch], () =>
    productService.fetchProducts(dataSearch),
  );
  const { items = [] } = data ?? {};

  const newArr = useMemo(() => shuffleArray(items), [items]);

  const productIndex = newArr.findIndex((item) => item.id === target);

  const imgPositionInWheel = (idx: number) => {
    return -idx * (360 / newArr.length);
  };

  return (
    <motion.div className='slot'>
      {newArr.map((item, idx) => (
        <motion.img
          key={idx}
          src={item.image}
          initial={{ x: 0 }}
          style={{
            originZ: isMobile ? -120 : -320,
            rotateX: imgPositionInWheel(idx),
          }}
          animate={{
            rotateX: -360 * (productIndex - 1) + imgPositionInWheel(idx - productIndex),
          }}
          transition={{ type: 'tween', duration: 2 }}
        />
      ))}
    </motion.div>
  );
};
export default Slot;
