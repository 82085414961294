import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type SystemState = {
  isReady: boolean;
};

const systemSlice = createSlice({
  name: 'system',
  initialState: { isReady: true } as SystemState,
  reducers: {
    saveSystem: (state, { payload }) => {
      return payload;
    },
  },
});

export const { saveSystem } = systemSlice.actions;

export const systemSelector = ({ system }: RootState) => system;

export default systemSlice.reducer;
