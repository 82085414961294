import { Phone, Person, Redeem, ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components';
import { parse } from 'qs';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const RegisterScreen = () => {
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const { refCode } = parse(location.search, { ignoreQueryPrefix: true });

  const { password } = watch();

  const { mutate: register, isLoading } = useMutation(authService.register, {
    onSuccess: (data, vars) => {
      authService
        .login(vars)
        .then((profile) => {
          dispatch(signIn(profile));
        })
        .then(() => {
          authService.getProfile().then((profile) => {
            dispatch(signIn(profile));
          });
        });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      register(values as RegisterBody);
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <div className='flex h-screen items-center justify-center md:justify-end rounded-lg md:p-10 relative'>
      <div className='flex items-center absolute top-4 left-0 md:hidden'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <span className='font-semibold text-lg'>Quay lại</span>
      </div>
      <div className='flex flex-col md:min-w-[380px] w-full md:w-auto gap-y-10'>
        <div className='flex justify-center'>
          <img src={require('assets/images/Auth.Logo.png')} alt='' />
        </div>
        <div>
          <Typography variant='h4' align='center'>
            ĐĂNG KÝ TÀI KHOẢN
          </Typography>
          <div className='flex flex-col items-stretch gap-4 my-12'>
            <Controller
              name='phone'
              defaultValue=''
              control={control}
              rules={{
                required: 'Số điện thoại không được để trống',
                validate: (value) =>
                  new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/).test(value) || 'Số điện thoại không hợp lệ',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Số điện thoại'
                  color='warning'
                  sx={{
                    background: '#0000',
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: 'var(--color-paper-white)',
                      borderRadius: 16,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name='username'
              defaultValue=''
              control={control}
              rules={{
                required: 'Tên không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Tên'
                  color='warning'
                  sx={{
                    background: '#0000',
                    borderRadius: 1,
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: 'var(--color-paper-white)',
                      borderRadius: 16,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Mật khẩu'
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name='confirmPassword'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu không được để trống',
                validate: {
                  match: (value) => value === password || 'Mật khẩu không khớp',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Xác nhận mật khẩu'
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name='passwordWithdraw'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu rút tiền không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Nhập mật khẩu rút tiền'
                  color='warning'
                  onKeyDown={handleKeyDown}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name='refCode'
              defaultValue={refCode}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Mã mời'
                  color='warning'
                  sx={{
                    background: '#0000',
                    borderRadius: 1,
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: 'var(--color-paper-white)',
                      borderRadius: 16,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Redeem />
                      </InputAdornment>
                    ),
                  }}
                  disabled={!!refCode}
                />
              )}
            />

            <LoadingButton
              size='large'
              variant='contained'
              color='secondary'
              disableElevation={false}
              onClick={handleClickSubmit}
              loading={isLoading}
            >
              Đăng Ký
            </LoadingButton>

            <div className='flex justify-center text-sm gap-1 z-20'>
              <span className='text-secondary-main'>Đã có tài khoản,</span>
              <Link className='font-medium text-secondary-main hover:text-secondary-dark' to={authRoute.login.url}>
                đăng nhập ngay
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
