import { Home } from 'views/Home';
import { Order } from 'views/Order';
import { ListOrder } from 'views/Order/components';
import { Wallet } from 'views/Wallet';

const privateRoute = {
  home: {
    path: '/',
    url: '/',
    name: 'TRANG CHỦ',
    component: Home,
    requiredLogin: false,
  },
  wallet: {
    path: '/wallet',
    url: '/wallet',
    name: 'VÍ TIỀN',
    component: Wallet,
    requiredLogin: true,
  },
  order: {
    path: '/order',
    url: '/order',
    name: 'GỬI ĐƠN',
    component: Order,
    requiredLogin: true,
  },
  orderList: {
    path: '/order/list',
    url: '/order/list',
    name: 'GỬI ĐƠN',
    component: ListOrder,
    requiredLogin: true,
  },
};

export default privateRoute;
