import { ArrowBack, Person } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { authRoute, privateRoute } from 'routes';
import { authService } from 'services';
import { profileSelector, signIn } from 'reducers/profileSlice';

const LoginScreen = () => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector(profileSelector);

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: (data) => {
      dispatch(signIn(data));
    },
    onSettled: () => {
      if (isLoggedIn) {
        authService.getProfile().then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values as LoginBody);
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <div className='flex h-screen items-center justify-center md:justify-end rounded-lg md:p-10 relative'>
      <div className='flex items-center absolute top-4 left-0 xl:hidden'>
        <Link to={privateRoute.home.path}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='font-semibold text-lg'>Trang chủ</span>
      </div>
      <div className='flex flex-col md:min-w-[380px] w-full md:w-auto gap-y-10'>
        <div className='flex justify-center'>
          <img src={require('assets/images/Auth.Logo.png')} alt='' />
        </div>
        <div>
          <Typography variant='h4' align='center'>
            ĐĂNG NHẬP TÀI KHOẢN
          </Typography>
          <div className='flex flex-col items-stretch gap-4 my-12'>
            <Controller
              name='username'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Tên'
                  color='warning'
                  sx={{
                    background: '#0000',
                    borderRadius: 1,
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: 'var(--color-paper-white)',
                      borderRadius: 16,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu không được để trống',
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Mật khẩu'
                  onKeyDown={handleKeyDown}
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <LoadingButton
              size='large'
              variant='contained'
              color='secondary'
              loading={isLoading}
              disableElevation={false}
              onClick={handleClickSubmit}
            >
              Đăng Nhập
            </LoadingButton>

            <div className='flex justify-center gap-2 text-sm z-20'>
              <Link className='font-medium text-secondary-main hover:text-secondary-dark' to={authRoute.register.url}>
                Tạo tài khoản mới
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
