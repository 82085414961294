import { Paper, Container, Box, LinearProgress, Dialog, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { formatBalance } from 'utils/common';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { orderService } from 'services';
import { OrderRecordType } from 'models/Order';
import { Slot } from '.';
import { PopupNotification, PopupOrder } from './components';
import commissionService from 'services/commission';

const Order = () => {
  const { balance, balanceDiscount, countOrder, isLoggedIn, vip, isLockOrder } = useSelector(profileSelector);

  const [order, setOrder] = useState<OrderRecordType>();
  const [openPopupOrder, setOpenPopupOrder] = useState(false);
  const [openPopupNotification, setOpenPopupNotification] = useState(isLockOrder ?? false);

  const [isSpin, setIsSpin] = useState(false);

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const { mutateAsync: orderSpin, isLoading } = useMutation(orderService.orderSpin, {
    onSuccess: (data) => {
      setOrder(data);
    },
    onError: () => {
      setIsSpin(false);
    },
  });

  useEffect(() => {
    if (isLockOrder) {
      setOpenPopupNotification(true);
    }
  }, [isLockOrder]);

  const handleOrderSpin = async () => {
    try {
      setIsSpin(true);
      await orderSpin();
      await timeout(2000);
      setOpenPopupOrder(true);
    } catch (error) {
      console.log(error);
    }
  };

  const { data: commission } = useQuery(
    ['commissionService.fetchCommissions'],
    () => commissionService.fetchCommissions(),
    {
      enabled: isLoggedIn,
    },
  );

  const vipTotalOrder = useMemo(
    () => commission?.items.find((item) => item.vip === vip)?.totalOrder ?? 0,
    [vip, commission],
  );

  return (
    <>
      <Container maxWidth='xl' className='mb-16 xl:mb-0'>
        <Paper className='rounded-none p-2 md:p-6'>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className='bg-paper-white p-6 rounded-xl min-w-[320px]'>
                <div className='text-2xl text-secondary-main font-semibold'>{formatBalance(balance)}</div>
                <div className='text-xs'>TỔNG TÀI SẢN</div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className='bg-paper-white p-6 rounded-xl min-w-[320px]'>
                <div className='text-2xl text-secondary-main font-semibold'>{formatBalance(balanceDiscount)}</div>
                <div className='text-xs'>HOA HỒNG NGÀY HÔM NAY</div>
              </Paper>
            </Grid>
          </Grid>
          <Container
            maxWidth='md'
            className='my-6 flex justify-center min-h-[360px] md:min-h-[640px] items-center border-4 border-secondary-main'
          >
            {[0, 1, 2].map((i) => (
              <Slot key={i} target={order?.product._id!} />
            ))}
          </Container>
          <div className='flex justify-center pb-4'>
            <LoadingButton
              variant='contained'
              color='secondary'
              loading={isLoading}
              disableElevation={false}
              onClick={handleOrderSpin}
              disabled={isLockOrder || isSpin}
            >
              KẾT HỢP TỰ ĐỘNG
            </LoadingButton>
          </div>
        </Paper>
        <Paper className='rounded-none p-6 mt-6'>
          <div className='flex flex-col items-center justify-center'>
            ĐƠN HÀNG HÔM NAY{' '}
            <span className='font-semibold text-secondary-main'>
              {countOrder}/{vipTotalOrder}
            </span>
            <Box className='mt-6 w-full xl:w-1/3'>
              <LinearProgress
                variant='determinate'
                sx={{ borderRadius: 8, height: 12 }}
                value={(countOrder! / vipTotalOrder! > 1 ? 100 : (countOrder! / vipTotalOrder!) * 100) ?? 0}
                color='error'
              />
            </Box>
          </div>
          <div className='font-semibold text-xl my-4 inline-block border-b-[3px] py-2 border-secondary-main'>
            MÔ TẢ ĐÁNH GIÁ SẢN PHẨM
          </div>
          <div className='text-sm'>
            <p>(1) Bằng cách sử dụng công nghệ LBS, hệ thống phù hợp với các sản phẩm.</p>
            <p>Tự động không thể hủy giao dịch sản phẩm.</p>
          </div>
          <div className='text-sm mt-8'>
            <p>
              (2) Sản phẩm quản lý việc giám sát nền tảng. Đơn hàng chưa được xác nhận vào thời điểm trước khi hệ thống
              dùng.
            </p>
            <p>Hệ thống sẽ sắp xếp lại các sản phẩm mà khách hàng đã đặt.</p>
            <p>Tất cả các đơn đặt hàng mới phải bắt đầu đếm lại từ 1.</p>
            <p>Trong hệ thống, sản phẩm không được hủy.</p>
          </div>
        </Paper>
      </Container>
      <Dialog fullWidth maxWidth='sm' open={openPopupOrder} onClose={() => setOpenPopupOrder(false)}>
        <PopupOrder item={order!} setIsSpin={setIsSpin} onClose={() => setOpenPopupOrder(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={openPopupNotification} onClose={() => setOpenPopupNotification(false)}>
        <PopupNotification onClose={() => setOpenPopupNotification(false)} />
      </Dialog>
    </>
  );
};

export default Order;
